<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2">
        {{ $t('Order Information') }}
      </span>
      <b-row class="mt-1 pb-1">
        <b-col md="6">
          <b-row>
            <b-col mb="12">
              <b-col
                mb="6"
                class="d-flex align-items-center justify-content-between"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Customer') }}
                </span>
                <span class="w-50">
                  <feather-icon
                    :icon="checkIconType(orderItem)"
                    size="16"
                    style="margin-bottom: 5px;"
                  />
                  {{ getValueFromGivenObjectByKey(orderItem, 'customer.cust_name_dba', '&mdash;') }}
                </span>
              </b-col>
              <b-col
                mb="6"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Placed by') }}
                </span>
                <span class="w-50">
                  {{ getValueFromGivenObjectByKey(orderItem, 'billing_contact.fullName', '&mdash;') }}
                </span>
              </b-col>
            </b-col>
            <!--            <b-col mb="4" />-->
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col mb="8">
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Agent') }}
                </span>
                <span class="w-50">
                  <!--                  getValueFromGivenObjectByKey(orderItem, 'agent', '&mdash;')-->
                  {{ '?' }}
                </span>
              </b-col>
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('PO/Event Ref.') }}
                </span>
                <span class="w-50">
                  {{ getValueFromGivenObjectByKey(orderItem, 'reference', '&mdash;') }}
                </span>
              </b-col>
            </b-col>
            <b-col mb="4" />
          </b-row>
        </b-col>
        <b-col
          v-for="field of ['warehouseNotes']"
          :key="field"
          md="6"
          class="mt-1"
        >
          <component
            :is="RentalSalesPickFields[field].type"
            v-model="warehouseNote"
            :onchange="valueOfNote(warehouseNote)"
            style="width: 100%"
            v-bind="getProps(field, RentalSalesPickFields)"
          />
        </b-col>
        <b-col
          v-for="field of ['orderNotes']"
          :key="field"
          md="6"
          class="mt-1"
        >
          <component
            :is="RentalSalesPickFields[field].type"
            :value="getOrderNote"
            style="width: 100%"
            :is-editable="true"
            v-bind="getProps(field, RentalSalesPickFields)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'

export default {
  name: 'RentalSalesOrderInformation',
  components: { BCol, BRow },
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      warehouseNote: '',
    }
  },
  computed: {
    getOrderNote() {
      return this.orderItem.total_order_notes
    },
  },
  methods: {
    valueOfNote(data) {
      return this.$store.commit(`${this.MODULE_NAME}/SET_WAREHOUSE_NOTES`, data)
    },
    checkIconType(data) {
      const status = getValueFromGivenObjectByKey(data, 'customer.customer_detail.status', '')
      if (status === 1) {
        return 'LTickIcon'
      }
      if (status === 2) {
        return 'LNoIcon'
      }
      if (status === 3) {
        return 'LClockIcon'
      }
      return 'LWarningIcon'
    },
  },
  setup() {
    const { RentalSalesPickFields, MODULE_NAME } = config()
    return {
      getValueFromGivenObjectByKey,
      RentalSalesPickFields,
      MODULE_NAME,
      getProps,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
