export default {
  SET(state, data) {
    state.rentalSaleOrder = { ...data }
  },
  RESET_STATE(state) {
    state.inspectedItems = []
    state.pickedItems = []
    state.selectedItems = []
  },
  SET_ITEMS(state, data) {
    state.assetsItem = data
  },
  SET_PICKED_ITEMS(state, data) {
    // Preventing from duplicates
    if (!state.pickedItems.includes(data.id)) {
      state.pickedItems.push(data.id)
    }
  },
  REMOVE_PICKED_ITEMS(state, data) {
    state.pickedItems = state.pickedItems.filter(item => item !== data.id)
  },
  SET_RENTAL_SALES_INFORMATION(state, data) {
    state.getRentalSalesInformation = data
    state.assetsItem = data?.order_items
  },
  SET_SELECTED_ITEMS(state, data) {
    if (!state.selectedItems.includes(data.id)) {
      state.selectedItems.push(data.id)
    }
  },
  REMOVE_SELECTED_ITEMS(state, data) {
    state.selectedItems = state.selectedItems.filter(item => item !== data.id)
  },
  SET_INSPECTED_ITEMS(state, data) {
    if (!state.inspectedItems.includes(data.id)) {
      state.inspectedItems.push(data.id)
    }
  },
  REMOVE_INSPECTED_ITEMS(state, data) {
    state.inspectedItems = state.inspectedItems.filter(item => item !== data.id)
  },
  SET_RE_RENDER_COMPONENT_KEY(state) {
    state.reRenderComponentKey += 1
  },
  SET_WAREHOUSE_NOTES(state, data) {
    state.warehouseNotes = data
  },
}
