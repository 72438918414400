<template>

  <div
    id="rental-sales-order-items"
    class="bg-white px-1 rounded-lg"
  >
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ $t('Order Items') }}
    </h2>
    <scan
      :asset-item-serial-numbers="assetItemSerialNumbers"
      @getValue="handleCheckBySerialNumber"
    />
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ getCategoryForTitle() }}
    </h2>
    <l-table-list-collector
      ref="lTableRef"
      :table-columns="tableColumns"
      :module-name="MODULE_NAME"
      :fetched-data="assetsItem"
      :is-searchable.camel="false"
      :is-fullfillment="true"
      :row-active="{
        filedKey: 'pick_at',
        classes: 'highlightRow'
      }"
    >
      <template #head(action)>
        <div
          class="d-flex p-0"
        >
          <b-form-checkbox
            :indeterminate="isIndeterminate && !pickedAllItems"
            :checked="pickedAllItems"
            @change="handleCheckAllItems"
          />
        </div>
      </template>
      <template #cell(action)="{ data: { item }}">
        <div
          class="d-flex pl-1"
        >
          <b-form-checkbox
            :checked="selectedItems.includes(item.id)"
            @change="handleSelectedItems(item)"
          />
        </div>
      </template>
      <template #cell(state)="{ data: { item }}">
        <div
          class="d-flex pl-1"
        >
          <state-of-asset
            :data="item"
            :with-label="false"
          />
        </div>
      </template>
      <template #cell(sku)="{ data: { item }}">
        {{ getValueFromGivenObjectByKey(item, 'product.sku', '&mdash;') }}
      </template>
      <template #cell(name)="{ data: { item }}">
        {{ getValueFromGivenObjectByKey(item, 'product.name', '&mdash;') }}
      </template>
      <template #cell(location)="{ data: { item }}">
        {{ getValueFromGivenObjectByKey(item, 'location.level_name', '&mdash;') }}
      </template>
      <template #cell(asset_id)="{ data: { item }}">
        <sub-rental-assets :item="item" />
      </template>
      <template #cell(status)="{ data: { item }}">
        <div
          class="pl-1"
        >
          <b-form-checkbox
            :checked="pickedItems.includes(item.id)"
            @change="handleCheckItem($event, item)"
          />
        </div>
      </template>
      <template #cell(inspected_outbound)="{ data: { item }}">
        <div
          class="pl-1"
        >
          <b-form-checkbox
            :disabled="!item.pick_at"
            :checked="inspectedItems.includes(item.id)"
            @change="handleInspected($event, item)"
          />
        </div>
      </template>
    </l-table-list-collector>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { assetStates } from '@/enum/asset-statuses'
import Scan from '@/views/components/Scan/Scan.vue'
import { find, map } from 'lodash'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import SubRentalAssets from '@/views/main/warehouse/main-order/components/sub-rental-asset/SubRentalAsset.vue'

export default {
  name: 'RentalSalesOrderItems',
  components: {
    Scan, StateOfAsset, BFormCheckbox, LTableListCollector, SubRentalAssets,
  },
  computed: {
    selectedItems() {
      return this.$store.state[this.MODULE_NAME].selectedItems
    },
    inspectedItems() {
      return this.$store.state[this.MODULE_NAME].inspectedItems
    },
    pickedItems() {
      return this.$store.state[this.MODULE_NAME].pickedItems
    },
    assetStates() {
      return assetStates
    },
    isIndeterminate() {
      return this.assetsItem.some(item => item.picked)
    },
    pickedAllItems() {
      return this.selectedItems.length === this.assetsItem.length
    },
    assetsItem() {
      return this.$store.state[this.MODULE_NAME].assetsItem
    },
    assetItemSerialNumbers() {
      return map(this.assetsItem, 'inventory_item.serial_number')
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    handleCheckAllItems() {
      const isRemoving = this.pickedAllItems

      this.assetsItem.forEach(item => this.updateSelectedState(item, !isRemoving))
    },
    handleCheckItem(checked, data) {
      const checkedItems = this.assetsItem.map(item => (item.id === data.id ? { ...item, pick_at: checked } : item))
      this.$store.commit(`${this.MODULE_NAME}/SET_ITEMS`, checkedItems)
      this.updatePickedState(data, checked)
      this.updateSelectedState(data, true)
      if (!checked) {
        this.handleInspected(false, data)
        this.updateSelectedState(data, false)
      }
    },
    handleSelectedItems(item) {
      const isSelected = this.selectedItems.includes(item.id)
      if (isSelected) {
        this.updatePickedState(item, false)
        this.handleInspected(false, item)
      }

      this.updateSelectedState(item, !isSelected)
    },
    handleInspected(checked, item) {
      const isInspected = this.inspectedItems.includes(item.id)
      const action = isInspected || !checked ? 'REMOVE_INSPECTED_ITEMS' : 'SET_INSPECTED_ITEMS'
      this.$store.commit(`${this.MODULE_NAME}/${action}`, item)
    },

    handleCheckBySerialNumber(serialNumber) {
      const checkedItems = find(this.assetsItem, { inventory_item: { serial_number: serialNumber } })
      console.log('serialNumber', serialNumber, checkedItems, 'this.assetsItem', this.assetsItem)
      if (!checkedItems) return
      this.handleCheckItem(!checkedItems.picked, checkedItems.id)
    },
    getCategoryForTitle() {
      const category = this.assetsItem.map(item => item.product?.categories[0].name)
      return category[0]
    },
    updatePickedState(item, checked) {
      const action = checked ? 'SET_PICKED_ITEMS' : 'REMOVE_PICKED_ITEMS'
      this.$store.commit(`${this.MODULE_NAME}/${action}`, item)
    },

    updateSelectedState(item, checked) {
      const action = checked ? 'SET_SELECTED_ITEMS' : 'REMOVE_SELECTED_ITEMS'
      this.$store.commit(`${this.MODULE_NAME}/${action}`, item)
      if (!checked) {
        this.$store.commit(`${this.MODULE_NAME}/REMOVE_PICKED_ITEMS`, item)
        const checkedItems = this.assetsItem.map(data => (data.id === item.id ? { ...item, pick_at: checked } : data))
        this.$store.commit(`${this.MODULE_NAME}/SET_ITEMS`, checkedItems)
      }
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
