import html2canvas from 'html2canvas'

export default function printAppContent() {
  const appContentEl = document.getElementById('rental-sales-order-items')

  html2canvas(appContentEl).then(canvas => {
    const printWindow = window.open('', '_blank')
    const printDocument = printWindow.document

    printDocument.write(`
        <html>
          <head>
            <title>Print Canvas</title>
            <style>
              body { margin: 0;height: auto; }
              canvas { height: auto; width: 100%;}
            </style>
          </head>
          <body>
            <canvas id="printCanvas" style="display:block;"></canvas>
          </body>
        </html>
      `)

    const printCanvas = printDocument.getElementById('printCanvas')
    printCanvas.width = canvas.width
    printCanvas.height = canvas.height

    const printCtx = printCanvas.getContext('2d')
    printCtx.drawImage(canvas, 0, 0)

    printWindow.print()
    printWindow.close()
  })
}
