import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = () => 'orders/rental-sale-v2'

const Actions = crudActions(endpoint)
const getRentalSalesOrders = (ctx, queryParams) => axios.get(`${endpoint()}/${queryParams.order_id}/fulfillment`)
const pickUpRentalSalesOrder = (_, payload) => axios.post(`${endpoint()}/pick`, payload)
const splitOrders = (_, { id, payload }) => axios.post(`${endpoint()}/${id}/split`, payload)
const packUpRentalSalesOrder = (_, payload) => axios.post(`${endpoint()}/pack`, payload)

export default {
  ...Actions,
  getRentalSalesOrders,
  pickUpRentalSalesOrder,
  packUpRentalSalesOrder,
  splitOrders,
}
