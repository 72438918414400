<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <div class="d-flex">
      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.push({ name: 'home-warehouse-view' })"
      >
        {{ $t("Back to List") }}
      </b-button>
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        :disabled="!isSomeChecked"
        @click="handleSplit"
      >
        <span>{{ $t("Request Split Order") }}</span>
      </b-button>
    </div>
    <div class="d-flex">
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        @click="printAppContent"
      >
        <feather-icon
          icon="LPrintIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t("Print Pick List") }}</span>
      </b-button>

      <b-button
        variant="success"
        class="d-flex justify-content-center ml-2"
        style="width: 170px; height: 40px"
        :disabled="!allPickedIspected || isLoading"
        @click="handleSubmit"
      >
        <feather-icon
          icon="LPickIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t(PICKED_ACTION_STATE) }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import printAppContent from '@/views/main/warehouse/view/pick/rental-sales/components/print-items'
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'
import { assetStates } from '@/enum/asset-statuses'

export default {
  name: 'RentalSalesFormBottom',
  components: { BButton },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    assetStates() {
      return assetStates
    },
    id() {
      return this.$route.params.id
    },
    // readyForPick() {
    //   return this.getServiceInformation.state === 2
    // },
    selectedItems() {
      return this.$store.state[this.MODULE_NAME].selectedItems
    },
    pickedItems() {
      return this.$store.state[this.MODULE_NAME].pickedItems
    },
    inspectedItems() {
      return this.$store.state[this.MODULE_NAME].inspectedItems
    },
    getRentalSalesInformation() {
      return this.$store.state[this.MODULE_NAME].rentalSaleOrder
    },
    assetsItem() {
      return this.$store.state[this.MODULE_NAME].assetsItem
    },
    isSomeChecked() {
      return this.selectedItems.length > 0
    },
    allPickedIspected() {
      const assetsLength = this.assetsItem.length
      const allPicked = assetsLength === this.pickedItems.length
      const allInspected = assetsLength === this.inspectedItems.length
      return allPicked && allInspected
    },
    assetsItemPicked() {
      return this.assetsItem.every(item => item.pick_at)
    },
    getOrderNote() {
      return this.$store.state[this.MODULE_NAME].warehouseNotes
    },
  },
  methods: {
    async handleSplit() {
      const splitedItems = { order_product_ids: this.assetsItem.filter(item => item.pick_at).map(item => item.id) }
      this.isLoading = true
      try {
        await this.$store.dispatch(`${this.MODULE_NAME}/splitOrders`, {
          id: this.id,
          payload: splitedItems,
        })

        const res = await this.$store.dispatch(`${this.MODULE_NAME}/getRentalSalesOrders`, { order_id: this.id })
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET`, data)
        this.$store.commit(`${this.MODULE_NAME}/SET_ITEMS`, data.order_items)
      } catch (err) {
        this.errorNotification(this, err)
      } finally {
        this.isLoading = false
      }
    },
    handleSubmit() {
      const orderType = this.$store.state[this.MODULE_NAME].getRentalSalesInformation.order_id.split('-')[0]
      const payload = {
        order_product_ids: this.pickedItems,
        order_id: this.id,
        warehouse_notes: this.getOrderNote,
      }
      this.isLoading = true
      this.$store
        .dispatch(
          `${this.MODULE_NAME}/pickUpRentalSalesOrder`,
          payload,
        )
        .then(() => {
          this.$router.push({
            name: 'home-warehouse-pack-order',
            params: { id: this.id, orderType },
          })
        })
        .catch(err => {
          this.errorNotification(this, err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  setup() {
    const { MODULE_NAME, PICKED_ACTION_STATE, PACKED_ACTION_STATE } = config()
    return {
      printAppContent,
      MODULE_NAME,
      PICKED_ACTION_STATE,
      PACKED_ACTION_STATE,
    }
  },
}
</script>

<style scoped lang="scss"></style>
