export default {
  rentalSaleOrder: {},
  getRentalSalesInformation: {},
  inspectedItems: [],
  pickedItems: [],
  selectedItems: [],
  assetsItem: [],
  reRenderComponentKey: 0,
  warehouseNotes: '',
}
