<template>
  <div>
    <quote-status-bar
      :item="quoteStatusItem"
      :quote-status-bar="quoteStatusBarItems"
      class="ml-1"
    />
    <order-exception-status :item="rentalOrderInfo" />
    <b-row class="mt-2">
      <b-col cols="6">
        <rental-sales-dispatch :order-item="rentalOrderInfo" />
      </b-col>
      <b-col cols="6">
        <rental-sales-return :order-item="rentalOrderInfo" />
      </b-col>
    </b-row>
    <rental-sales-order-information
      class="mt-2"
      :order-item="rentalOrderInfo"
    />
    <rental-sales-order-items class="mt-2" />
    <rental-sales-form-bottom />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'
import mainConfig from '@/views/main/warehouse/config'
import { BCol, BRow } from 'bootstrap-vue'
import RentalSalesDispatch from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesDispatch.vue'
import RentalSalesReturn from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesReturn.vue'
import RentalSalesOrderInformation from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesOrderInformation.vue'
import RentalSalesOrderItems from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesOrderItems.vue'
import RentalSalesFormBottom from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesFormBottom.vue'
import OrderExceptionStatus
from '@/views/main/warehouse/main-order/components/order-exception-status/OrderExceptionStatus.vue'

export default {
  name: 'RentalSalesForm',
  components: {
    OrderExceptionStatus,
    RentalSalesFormBottom,
    RentalSalesOrderItems,
    RentalSalesOrderInformation,
    RentalSalesReturn,
    RentalSalesDispatch,
    QuoteStatusBar,
    BCol,
    BRow,
  },
  computed: {
    rentalOrderInfo() {
      return this.$store.state[this.MODULE_NAME].getRentalSalesInformation
    },
    quoteStatusItem() {
      return this.rentalOrderInfo ? {
        state: this.rentalOrderInfo.fulfillment_state,
        status: this.rentalOrderInfo.fulfillment_status,
      } : {}
    },
    quoteStatusBarItems() {
      return this.quoteStatusItemsMerge(this.rentalOrderInfo.states, false)
    },
  },
  setup() {
    const { transferFields, MODULE_NAME, MODULE_NAME_CLONE } = config()
    const { quoteStatusItemsMerge } = mainConfig()

    return {
      transferFields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      quoteStatusItemsMerge,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
